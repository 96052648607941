import * as Sentry from "@sentry/gatsby";

if (process.env.GATSBY_SENTRY_DSN) {
    Sentry.init({
      dsn: `${process.env.GATSBY_SENTRY_DSN}`,
      tracesSampleRate: 0.01,
      ignoreErrors: [
        "Network Error",
        "Request aborted",
        "Request failed with status code",
        "top.GLOBALS",
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        "fb_xd_fragment",
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        "conduitPage",
      ],
      denyUrls: [
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /^https:\/\/www.deltamath.com\/app\/manager\/assets/i,
        /^https:\/\/www.deltamath.com\/app\/manager\/global/i,
      ],
    });
  }
  